<template>
    <transition name="fade" mode="out-in">
        <div class="input-container" :style="containerStyle">
            <div v-if="title" class="form-label">
                <span v-if="required" class="required">*</span><span></span
                >{{ title }}
            </div>
            <div class="checkbox-container" @click="setCheckboxFormGa">
                <Checkbox
                    v-for="(option, key) in options"
                    :key="key"
                    :name="option.label"
                    :label="option.label"
                    :value="option.value"
                    v-model="checkboxValue"
                />
            </div>
            <div v-if="isError" class="error-message">{{ errorMessage }}</div>
        </div>
    </transition>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue'

export default {
    emits: ['update:modelValue'],
    components: {
        Checkbox
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String
        },
        modelValue: {
            type: Object,
            default: function () {
                return {}
            }
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: '輸入有誤'
        },
        options: {
            type: Array,
            default: function () {
                return []
            }
        },
        width: {}
    },
    methods: {
        setCheckboxFormGa: function () {
            if (this.title)
                this.$setGaEvent(
                    `${this.name}${
                        this.required ? 'required' : ''
                    }CheckboxFilter`,
                    'click-Filter'
                )
        }
    },
    computed: {
        containerStyle: function () {
            const style = {}
            if (typeof this.width === 'string' && this.width.includes('%')) {
                style['width'] = this.width
            } else if (typeof this.width === 'number') {
                style['width'] = `${this.width}px`
            }
            return style
        }
    },
    watch: {
        checkboxValue: function () {
            this.$emit('update:modelValue', this.checkboxValue)
        },
        modelValue: function () {
            this.checkboxValue = this.modelValue
        }
    },
    data() {
        return {
            checkboxValue: this.modelValue
        }
    }
}
</script>

<style lang="scss" scoped>
.input-container {
    width: 210px;

    &:last-child {
        margin-right: 0px;
    }
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 40px;
    .checkbox-container {
        margin-bottom: 14px;
    }
}

.form-label {
    color: $sixth-black;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    .required {
        color: $primary-red;
    }
}

.error-message {
    position: absolute;
    font-size: 12px;
    color: $fourth-red;
    margin-top: 5px;
}
</style>
